@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Poppins", sans-serif;
  }
}
* {
  scroll-behavior: smooth;
}
/* html,
body,
#root,
main {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
} */
